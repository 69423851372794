
@import-normalize;
body {
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #777777;
  background-color: #14182a !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
