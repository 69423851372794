/*====Top Genres Css Start====*/
.ms_genres_wrapper {
	margin: 50px 0px 0px 0px;
}
.ms_genres_box {
	margin-bottom: 30px;
	position: relative;
	overflow: hidden;
	border-radius: 10px;
}
.ms_genres_box img {
	width: 100%;
	border-radius: 10px;
}
.ms_genres_box:hover .ms_box_overlay {
	top: 0;
}
.ms_genres_box:hover .ms_main_overlay {
	opacity: 1;
}
.ovrly_text_div {
	position: absolute;
	width: 100%;
	bottom: -40%;
	padding: 0px 20px;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
}
.ovrly_text_div span.ovrly_text1 a,
.ovrly_text_div span.ovrly_text2 a {
	color: #fff;
	text-transform: capitalize;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
}
.ovrly_text_div span.ovrly_text1 a:hover,
.ovrly_text_div span.ovrly_text2 a:hover {
	letter-spacing: 1px;
}
span.ovrly_text2 {
	float: right;
}
.ms_genres_box:hover .ovrly_text_div {
	bottom: 20px;
}
.ms_genres_box .ms_box_overlay_on {
	position: absolute;
	left: 0;
	content: "";
	width: 100%;
	height: 100%;
	top: 0;
	border-radius: 10px;
	background-image: -moz-linear-gradient( 90deg, rgb(20, 24, 42) 0%, rgb(237, 63, 179) 0%, rgb(52, 62, 105) 0%, rgba(32, 167, 196, 0) 100%);
	background-image: -webkit-linear-gradient( 90deg, rgb(20, 24, 42) 0%, rgb(237, 63, 179) 0%, rgb(52, 62, 105) 0%, rgba(32, 167, 196, 0) 100%);
	background-image: -ms-linear-gradient( 90deg, rgb(20, 24, 42) 0%, rgb(237, 63, 179) 0%, rgb(52, 62, 105) 0%, rgba(32, 167, 196, 0) 100%);
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
}
.ms_box_overlay_on .ovrly_text_div {
	bottom: 20px;
}
.ms_genres_box:hover .ms_box_overlay_on {
	opacity: 0;
	top: 100%;
}
