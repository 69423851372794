.ms_main_wrapper{


font-weight: 400;
line-height: 24px;
color: #777777;
font-size: 15px;
box-sizing: border-box;
}
.ms_content_wrapper {
	position: relative;
	margin-left: 160px;
	margin-right: 80px;
}
.padder_top80 {
    padding-top: 80px !important;
}
.ms_advr_wrapper {
	text-align: center;
	margin-top: 50px;
	margin-bottom: 10px;
}
.ms_advr_wrapper.ms_advr2 {
	margin: 31px 0px 58px;
}